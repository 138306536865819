export const getTrainingDataProgressApiUrl = (workspaceId, provider) =>
  `training-status/workspace/${workspaceId}?category=${provider}`;

export const getTrainingPageDataDetailsApiUrl = (workspaceId) =>
  `training-status/workspace/${workspaceId}/all`;

export const addFileByUploadDataSourceApiUrl = (workspaceId) =>
  `rpc/chat-bot/workspace/${workspaceId}/data-sources/add-files?type=CHAT_BOT`;

export const setRetrainScheduleFrequencyApiUrl = (workspaceId) =>
  `training-status/workspace/${workspaceId}/schedule-retrain`;

export const getGoogleDriveIntegrationsListApiUrl = (workspaceId) => `google-drive/workspace/${workspaceId}`;
