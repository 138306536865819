import {
  OPEN_DATA_SOURCE_SIDEBAR,
  CLOSE_DATA_SOURCE_SIDEBAR,
  SET_SELECTED_DATA_SOURCE,
  CLEAR_SELECTED_DATA_SOURCE,
  SET_CHANNEL_DETAILS_TRAINING,
  SET_SIDEBAR_OPEN_SOURCE_FROM_INTEGRATION,
  SET_TRAINING_DATA_PROGRESS_LOADING,
  GET_TRAINING_DATA_PROGRESS_DETAILS_URL,
  GET_TRAINING_DATA_PROGRESS_DETAILS_NOTION,
  GET_TRAINING_DATA_PROGRESS_DETAILS_CONFLUENCE,
  GET_TRAINING_DATA_PROGRESS_DETAILS_FILES,
  GET_TRAINING_DATA_PROGRESS_DETAILS_GOOGLE_DRIVE,
  GET_TRAINING_DATA_PROGRESS_DETAILS_ZENDESK,
  GET_TRAINING_DATA_PROGRESS_DETAILS_FRESHDESK,
  GET_TRAINING_DATA_PROGRESS_DETAILS_INTERCOM,
  GET_TRAINING_DATA_PROGRESS_DETAILS_HUBSPOT,
  GET_TRAINING_DATA_PROGRESS_DETAILS_SLACK,
  GET_TRAINING_DATA_PROGRESS_DETAILS_DISCORD,
  GET_TRAINING_DATA_PROGRESS_DETAILS_MS_TEAMS,
  GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_HUBSPOT,
  GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_SALESFORCE,
  GET_TRAINING_PAGE_DATA_DETAILS,
  SET_TRAINING_PAGE_DATA_LOADING,
  GET_TRAINING_PAGE_DATA_DETAILS_WITH_WS,
  SET_IS_DIRTY_FLAG_FOR_POLLING,
  GET_GOOGLE_DRIVE_INTEGRATIONS,
} from 'redux/trainingProvider/types.js';
import { updateObjectWithChanges } from 'redux/trainingProvider/utils.js';

const initialState = {
  isSidebarOpen: false,
  selectedDataSource: null,
  channelListTraining: [],
  isSidebarOpenSourceFromIntegration: false,
  trainingDataProgressDetailsUrl: null,
  trainingDataProgressDetailsNotionUrl: null,
  trainingDataProgressDetailsConfluenceUrl: null,
  trainingDataProgressDetailsFilesUpload: null,
  trainingDataProgressDetailsGoogleDrive: null,
  trainingDataProgressDetailsZendesk: null,
  trainingDataProgressDetailsFreshdesk: null,
  trainingDataProgressDetailsIntercom: null,
  trainingDataProgressDetailsHubspot: null,
  trainingDataProgressDetailsCrmHubspot: null,
  trainingDataProgressDetailsCrmSalesforce: null,
  trainingDataProgressDetailsSlack: null,
  trainingDataProgressDetailsDiscord: null,
  trainingDataProgressDetailsMsTeams: null,
  trainingDataProgressLoading: false,
  trainingPageDetails: null,
  trainingPageDetailsLoading: false,
  isDirtyFlagForPolling: false,
  googleDriveIntegrationInfo: null,
};

const trainingSupportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_DATA_SOURCE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: true,
      };
    case CLOSE_DATA_SOURCE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: false,
        selectedDataSource: null,
      };
    case SET_SELECTED_DATA_SOURCE:
      return {
        ...state,
        selectedDataSource: payload,
      };
    case CLEAR_SELECTED_DATA_SOURCE:
      return {
        ...state,
        selectedDataSource: null,
      };
    case SET_CHANNEL_DETAILS_TRAINING:
      return {
        ...state,
        channelListTraining: payload,
      };
    case SET_SIDEBAR_OPEN_SOURCE_FROM_INTEGRATION:
      return {
        ...state,
        isSidebarOpenSourceFromIntegration: payload,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_URL:
      return {
        ...state,
        trainingDataProgressDetailsUrl: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_NOTION:
      return {
        ...state,
        trainingDataProgressDetailsNotionUrl: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_CONFLUENCE:
      return {
        ...state,
        trainingDataProgressDetailsConfluenceUrl: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_FILES:
      return {
        ...state,
        trainingDataProgressDetailsFilesUpload: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_GOOGLE_DRIVE:
      return {
        ...state,
        trainingDataProgressDetailsGoogleDrive: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_ZENDESK:
      return {
        ...state,
        trainingDataProgressDetailsZendesk: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_FRESHDESK:
      return {
        ...state,
        trainingDataProgressDetailsFreshdesk: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_INTERCOM:
      return {
        ...state,
        trainingDataProgressDetailsIntercom: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_HUBSPOT:
      return {
        ...state,
        trainingDataProgressDetailsHubspot: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_SLACK:
      return {
        ...state,
        trainingDataProgressDetailsSlack: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_DISCORD:
      return {
        ...state,
        trainingDataProgressDetailsDiscord: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_MS_TEAMS:
      return {
        ...state,
        trainingDataProgressDetailsMsTeams: payload,
        trainingDataProgressLoading: false,
      };
    case SET_TRAINING_DATA_PROGRESS_LOADING:
      return {
        ...state,
        trainingDataProgressLoading: payload,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_HUBSPOT:
      return {
        ...state,
        trainingDataProgressDetailsCrmHubspot: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_DATA_PROGRESS_DETAILS_CRM_SALESFORCE:
      return {
        ...state,
        trainingDataProgressDetailsCrmSalesforce: payload,
        trainingDataProgressLoading: false,
      };
    case GET_TRAINING_PAGE_DATA_DETAILS:
      return {
        ...state,
        trainingPageDetails: payload,
        trainingPageDetailsLoading: false,
      };
    case GET_TRAINING_PAGE_DATA_DETAILS_WITH_WS:
      const updatedPayload = updateObjectWithChanges({ ...state.trainingPageDetails }, payload);
      return {
        ...state,
        trainingPageDetails: updatedPayload,
        trainingPageDetailsLoading: false,
      };
    case SET_TRAINING_PAGE_DATA_LOADING:
      return {
        ...state,
        trainingPageDetailsLoading: true,
      };
    case SET_IS_DIRTY_FLAG_FOR_POLLING:
      return {
        ...state,
        isDirtyFlagForPolling: payload,
      }
    case GET_GOOGLE_DRIVE_INTEGRATIONS:
      return {
        ...state,
        googleDriveIntegrationInfo: payload,
      }
    default:
      return state;
  }
};
export default trainingSupportReducer;
