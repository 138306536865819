import pdfModuleIcon from 'assets/images/trainingPage/pdfModuleIcon.svg';
import urlModuleIcon from 'assets/images/trainingPage/urlModuleIcon.svg';
import freshdeskModuleIcon from 'assets/images/trainingPage/freshdeskModuleIcon.svg';
import confluenceModuleIcon from 'assets/images/trainingPage/confluenceModuleIcon.svg';
import notionModuleIcon from 'assets/images/trainingPage/notionModuleIcon.svg';
import zendeskModuleIcon from 'assets/images/trainingPage/zendeskModuleIcon.svg';
import salesForceModuleIcon from 'assets/images/trainingPage/salesForceModuleIcon.svg';
import hubspotModuleIcon from 'assets/images/trainingPage/hubspotModuleIcon.svg';
import intercomModuleIcon from 'assets/images/trainingPage/intercomModuleIcon.svg';
import slackModuleIcon from 'assets/images/trainingPage/slackModuleIcon.svg';
import discordModuleIcon from 'assets/images/trainingPage/discordModuleIcon.svg';
import googleDriveModuleIcon from 'assets/images/trainingPage/googleDriveModuleIcon.svg';
import teamsModuleIcon from 'assets/images/trainingPage/teamsModuleIcon.svg';
import {
  CONFLUENCE_CONNECT_AUTH_URL,
  DISCORD_AUTH_URL,
  GOOGLE_DRIVE_CONNECT_AUTH_URL,
  HUBSPOT_AUTH_URL,
  INTERCOM_CONNECT_AUTH_URL,
  MS_TEAMS_CONNECT_AUTH_URL,
  NOTION_CONNECT_AUTH_URL,
  SLACK_AUTH_URL,
} from '../../constant/urlConstants';
import { CheckIfIntegrationActive } from '../../context/useIntegration';

// Maps integration keys from the training page to their respective display texts.
export const TrainingPageIntegrationDisplayNames = {
  documents: 'Documents',
  supportCRM: 'Support and CRM',
  messages: 'Messaging',
};

export const TrainingDataSourceType = {
  URL: 'URL',
  PDF: 'PDF',
  CSV: 'CSV',
  EXCEL: 'EXCEL',
  DOCUMENT: 'DOCUMENT',
  FILES: 'FILES',
  SLACK: 'SLACK',
  DISCORD: 'DISCORD',
  TWITTER: 'TWITTER',
  DISCOURSE: 'DISCOURSE',
  EMAIL: 'EMAIL',
  CONFLUENCE: 'CONFLUENCE',
  ZENDESK: 'ZENDESK',
  FRESHDESK: 'FRESHDESK',
  NOTION: 'NOTION',
  HUBSPOT: 'HUBSPOT',
  CRM_HUBSPOT: 'CRM_HUBSPOT',
  GITHUB: 'GITHUB',
  CHROME: 'CHROME',
  INTERCOM: 'INTERCOM',
  GOOGLE_DRIVE: 'GOOGLE_DRIVE',
  SALESFORCE: 'SALESFORCE',
  MS_TEAMS: 'MS_TEAMS',
};
/**
 * 
 * // Define the type for individual integration items.
 * type IntegrationItem = {
  integrationName: string;
  displayName: string;
  displayIcon: any; // Replace `any` with a more specific type if available, e.g., `React.ComponentType`.
  meta: string;
  displayMeta: boolean;
  learnMoreText?: string; // Optional properties with `?`
  displayLearnMoreText?: boolean;
  badgeText?: string;
  newIntegrationDisplayBadge?: boolean;
};

* // Define the type for mapping categories to their respective integration items.
* type IntegrationMapper = {
  document: IntegrationItem[];
  support: IntegrationItem[];
  messaging: IntegrationItem[];
};
 */

export const IntegrationMapper = {
  documents: [
    {
      integrationName: TrainingDataSourceType.FILES,
      displayName: 'PDF, Excel and CSV',
      displayIcon: pdfModuleIcon,
      meta: 'Train bot on your PDF, CSV and Excel files.',
      displayMeta: false,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8205270-how-to-train-your-bot-on-urls-and-pdfs',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      integrationSelectedStatus: true,
      displayDataMeta: true,
      showEncrypted: true,
      showMasked: true,
      placementOrder: 1,
    },
    {
      integrationName: TrainingDataSourceType.URL,
      displayName: 'URL',
      displayIcon: urlModuleIcon,
      meta: 'Train bot on your URL’s here.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8205270-how-to-train-your-bot-on-urls-and-pdfs',
      displayLearnMoreText: true,
      badgeText: '',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      placementOrder: 2,
    },
    {
      integrationName: TrainingDataSourceType.CONFLUENCE,
      displayName: 'Confluence',
      displayIcon: confluenceModuleIcon,
      meta: 'Train bot using Confluence docs.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8569874-how-to-integrate-and-train-the-bot-on-confluence-docs',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: true,
      isSourceAnIntegration: true,
      authUrl: CONFLUENCE_CONNECT_AUTH_URL,
      placementOrder: 3,
    },
    {
      integrationName: TrainingDataSourceType.NOTION,
      displayName: 'Notion',
      displayIcon: notionModuleIcon,
      meta: 'Train bot using Notion docs.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8600603-how-to-integrate-and-train-the-bot-on-notion-docs',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: true,
      isSourceAnIntegration: true,
      authUrl: NOTION_CONNECT_AUTH_URL,
      placementOrder: 4,
    },
    {
      integrationName: TrainingDataSourceType.GOOGLE_DRIVE,
      displayName: 'Google Drive',
      integrationDataKey: 'googleDrive',
      displayIcon: googleDriveModuleIcon,
      meta: 'Train bot using Sheets, Sites, Docs and PDFs.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/9271165-how-to-integrate-and-train-threado-ai-on-google-drive-docs',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: true,
      isSourceAnIntegration: true,
      authUrl: GOOGLE_DRIVE_CONNECT_AUTH_URL,
      placementOrder: 5,
    },
  ],
  supportCRM: [
    {
      integrationName: TrainingDataSourceType.FRESHDESK,
      displayName: 'Freshdesk',
      displayIcon: freshdeskModuleIcon,
      meta: 'Train bot using tickets and help articles.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8600865-how-to-integrate-and-train-the-bot-on-freshdesk',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: false,
      isSourceAnIntegration: true,
      placementOrder: 1,
      // authUrl: HUBSPOT_AUTH_URL,
    },
    {
      integrationName: TrainingDataSourceType.ZENDESK,
      displayName: 'Zendesk',
      displayIcon: zendeskModuleIcon,
      meta: 'Train bot using tickets and help articles.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8569878-how-to-integrate-and-train-the-bot-on-zendesk',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: false,
      isSourceAnIntegration: true,
      placementOrder: 2,
      // authUrl: HUBSPOT_AUTH_URL,
    },
    {
      integrationName: TrainingDataSourceType.SALESFORCE,
      displayName: 'Salesforce',
      displayIcon: salesForceModuleIcon,
      meta: 'Train bot using Salesforce CRM data.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/9577087-how-to-connect-and-train-threado-ai-on-your-salesforce-account',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isSourceAnIntegration: true,
      isIntegrationRedirectable: true,
      placementOrder: 3,
    },
    {
      integrationName: TrainingDataSourceType.CRM_HUBSPOT,
      displayName: 'HubSpot',
      integrationDataKey: 'crmHubspot',
      displayIcon: hubspotModuleIcon,
      meta: 'Train bot using HubSpot CRM data.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/9154588-how-to-install-and-train-threao-ai-on-your-hubspot-account',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: true,
      isSourceAnIntegration: true,
      placementOrder: 4,
      // authUrl: HUBSPOT_AUTH_URL,
    },
    {
      integrationName: TrainingDataSourceType.INTERCOM,
      displayName: 'Intercom',
      displayIcon: intercomModuleIcon,
      meta: 'Train bot using conversations and help articles.',
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/9018884-how-to-integrate-and-train-the-bot-on-intercom',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: true,
      isSourceAnIntegration: true,
      authUrl: INTERCOM_CONNECT_AUTH_URL,
      placementOrder: 5,
    },
  ],
  messages: [
    {
      integrationName: TrainingDataSourceType.SLACK,
      displayName: 'Slack',
      displayIcon: slackModuleIcon,
      meta: "Train bot using your community's conversations.",
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8193255-how-to-install-threado-ai-on-your-slack-channels',
      displayLearnMoreText: true,
      badgeText: 'New',
      newIntegrationDisplayBadge: false,
      integrationSelectedStatus: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: true,
      isSourceAnIntegration: true,
      authUrl: SLACK_AUTH_URL,
      placementOrder: 1,
    },
    {
      integrationName: TrainingDataSourceType.DISCORD,
      displayName: 'Discord',
      displayIcon: discordModuleIcon,
      meta: "Train bot using your community's conversations.",
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/8974477-how-to-install-the-bot-on-your-discord-community',
      displayLearnMoreText: true,
      // badgeText: 'New',
      newIntegrationDisplayBadge: false,
      integrationSelectedStatus: false,
      commingSoonDisplayBadge: false,
      isIntegrationRedirectable: true,
      isSourceAnIntegration: true,
      authUrl: DISCORD_AUTH_URL,
      placementOrder: 2,
    },
    {
      integrationName: TrainingDataSourceType.MS_TEAMS,
      displayName: 'Teams',
      integrationDataKey: 'msTeams',
      displayIcon: teamsModuleIcon,
      meta: "Train bot using your community's conversations.",
      displayMeta: true,
      learnMoreText: ' Know more',
      learnMoreLink:
        'https://help.threado.com/en/articles/9669311-how-to-connect-your-microsoft-teams-account-with-threado-ai',
      displayLearnMoreText: true,
      badgeText: 'New',
      commingSoonDisplayBadge: false,
      newIntegrationDisplayBadge: false,
      integrationSelectedStatus: false,
      isIntegrationRedirectable: true,
      authUrl: MS_TEAMS_CONNECT_AUTH_URL,
      isSourceAnIntegration: true,
      placementOrder: 3,
    },
  ],
};

export const GetIntegrationMapper = (
  mapper,
  communityDetails,
  traingPageDetailsBySubHeading,
  googleDriveIntegrationInfo,
) => {
  Object.keys(mapper).forEach((key) => {
    mapper[key] = mapper[key]
      .map((localIntegrationObj) => ({
        ...localIntegrationObj,
        integrationDetails: localIntegrationObj.isSourceAnIntegration
          ? communityDetails?.integrations?.[localIntegrationObj.integrationName.toLowerCase()]?.[0]
          : null,
        isIntegrated: localIntegrationObj.isSourceAnIntegration
          ? !!communityDetails?.integrations?.[localIntegrationObj.integrationName.toLowerCase()] &&
            !communityDetails?.integrations?.[
              localIntegrationObj.integrationName.toLowerCase()
            ]?.[0]?.isDummy
          : traingPageDetailsBySubHeading?.[
              localIntegrationObj.integrationDataKey ||
                localIntegrationObj.integrationName.toLowerCase()
            ] !== undefined
          ? !!getValueWithTextTitleOfDataType(
              traingPageDetailsBySubHeading?.[
                localIntegrationObj.integrationDataKey ||
                  localIntegrationObj.integrationName.toLowerCase()
              ]?.data,
            )
          : null,
      }))
      .sort((a, b) => {
        // Prioritize integrated items, but keep non-integrated ones in original placement order
        if (a.isIntegrated && !b.isIntegrated) return -1; // a is integrated, b is not
        if (!a.isIntegrated && b.isIntegrated) return 1; // b is integrated, a is not
        // When both are integrated or both are not, sort by placementOrder
        return a.placementOrder - b.placementOrder;
      });
  });
  return mapper;
};

export const filterDataForTrainingWithSearchAndStatus = (
  statusDetails,
  type,
  searchString,
  selectedStatusTab,
) => {
  // Check if the type exists and has documents in statusDetails
  if (!statusDetails || !statusDetails[type] || !statusDetails[type].documents) {
    return {
      lastSyncAt: 'Not Available',
      isLoading: false,
      documents: [], // Return an empty array if the required data is not present
    };
  }
  // Filter out documents using filter to apply filtering using search and status
  const filteredDocuments = statusDetails[type].documents.filter((doc) => {
    // Filter documents by the specified status and search string
    // TODO: ADD status filter: doc.status === selectedStatusTab
    if (!searchString) {
      return true;
    } else {
      return doc.fileName.includes(searchString);
    }
  });

  // Return the filtered documents along with the metadata
  return {
    lastSyncAt: statusDetails[type].lastSyncAt,
    isLoading: statusDetails[type].isLoading,
    documents: filteredDocuments,
  };
};

const keyNameMapper = {
  pdf: 'PDF',
  sheet: 'Sheet',
  doc: 'Doc',
  site: 'Site',
  csv: 'CSV',
  excel: 'Excel',
  url: 'URL',
  ticket: 'Ticket',
  article: 'Article',
  // cannedResponse: 'Canned response',
  channel: 'Channel',
  conversation: 'Conversation',
  deals: 'Deal',
  contacts: 'Contact',
  companies: 'Companie',
};

export const getValueWithTextTitleOfDataType = (data) => {
  let response = '';
  let totalFailed = 0;
  let hasFailedCount = false;

  for (const key in data) {
    if (data.hasOwnProperty(key) && keyNameMapper.hasOwnProperty(key) && data?.[key]) {
      const total = data[key].total;
      const failed = data[key].failed;

      // Only add to the response if the total is a truthy value
      if (total) {
        const formattedKey = keyNameMapper[key];
        const formattedKeyWithPrefix =
          total > 1 ? formattedKey + 's' : key === 'companies' ? 'Company' : formattedKey;
        response += `${total} ${formattedKeyWithPrefix}  `;
      }

      // Check if failed is a valid number and greater than 0
      if (failed && !isNaN(failed)) {
        totalFailed += failed;
        hasFailedCount = true;
      }
    }
  }
  // Only append the failed count if it is valid and greater than 0
  if (hasFailedCount) {
    response += `${totalFailed} Failed`;
  }

  return response.trim();
};

export const getCumulativeValueFromDataList = (dataList) => {
  const cumulativeData = {};
  let totalFailed = 0;
  let hasFailedCount = false;

  dataList.forEach((dataItem) => {
    const data = dataItem.data;
    for (const key in data) {
      if (data.hasOwnProperty(key) && keyNameMapper.hasOwnProperty(key) && data?.[key]) {
        const total = data[key].total || 0;
        const failed = data[key].failed || 0;

        if (total > 0) {
          if (!cumulativeData[key]) {
            cumulativeData[key] = { total: 0, failed: 0 };
          }
          cumulativeData[key].total += total;
        }

        if (failed > 0) {
          cumulativeData[key].failed += failed;
          hasFailedCount = true;
        }
      }
    }
  });

  // Construct the final response string
  let response = '';
  for (const key in cumulativeData) {
    if (cumulativeData.hasOwnProperty(key)) {
      const total = cumulativeData[key].total;
      const failed = cumulativeData[key].failed;
      const formattedKey = keyNameMapper[key];
      const formattedKeyWithPrefix =
        total > 1 ? formattedKey + 's' : key === 'companies' ? 'Company' : formattedKey;

      if (total > 0) {
        response += `${total} ${formattedKeyWithPrefix}  `;
      }

      if (failed > 0) {
        totalFailed += failed;
      }
    }
  }

  // Only append the total failed count if any failed counts were found
  if (hasFailedCount) {
    response += `${totalFailed} Failed`;
  }

  return response.trim();
};

// export const getFinalResultFromDataList = (dataList) => {
//   if(!dataList) {
//     return
//   }
//   let finalResponse = '';
//   dataList.forEach((dataItem) => {
//     finalResponse += getValueWithTextTitleOfDataType(dataItem.data) + ' ';
//   });

//   return finalResponse.trim();
// };

export const getUrlsListByStatusAndSearchString = (urlsList, searchText, selectedUrlTab) => {
  // Check if urlsList is empty or not provided
  if (!Array.isArray(urlsList) || !urlsList?.length) {
    return [];
  }

  // Ensure searchText is in lowercase for case-insensitive comparison
  const lowerCaseSearchText = searchText?.toLowerCase();

  // Filter the urlsList based on searchText and selectedUrlTab
  const filteredUrls = urlsList.filter((url) => {
    const matchesSearchText =
      lowerCaseSearchText === '' || url?.indexURL.toLowerCase().includes(lowerCaseSearchText);
    const matchesSelectedTab = selectedUrlTab?.includes(url?.status);
    return matchesSearchText && matchesSelectedTab;
  });

  return filteredUrls;
};

export const getFilesListByStatusAndSearchString = (filesList, searchText, selectedUrlTab) => {
  // Check if urlsList is empty or not provided
  if (!Array.isArray(filesList) || !filesList?.length) {
    return [];
  }

  // Ensure searchText is in lowercase for case-insensitive comparison
  const lowerCaseSearchText = searchText?.toLowerCase();

  // Filter the urlsList based on searchText and selectedUrlTab
  const filteredFiles = filesList.filter((file) => {
    const matchesSearchText =
      lowerCaseSearchText === '' || file?.fileName.toLowerCase().includes(lowerCaseSearchText);
    const matchesSelectedTab = selectedUrlTab?.includes(file?.status);
    return matchesSearchText && matchesSelectedTab;
  });

  return filteredFiles;
};

export const scheduleOptionsList = [
  {
    name: 'Weekly',
    value: 'WEEKLY',
  },
  {
    name: 'Biweekly',
    value: 'BIWEEKLY',
  },
  {
    name: 'Monthly',
    value: 'MONTHLY',
  },
];

export const scheduleIntervalToDescriptionMapper = {
  WEEKLY: 'Auto-syncs every week',
  BIWEEKLY: 'Auto-syncs every two weeks',
  MONTHLY: 'Auto-syncs every month',
};